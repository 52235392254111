@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 94vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(32, 32, 32);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html, body {
  margin: 10;
  height: 100%;
}

.btn{
  margin-left:10px;
  }



.ar-button-class{
  position:         absolute;
  right:            4%;
  bottom:           4%;
  border-radius:    30%;
  -webkit-filter:   drop-shadow(1px 1px 3px rgb(15, 15, 15)); 
  filter:           drop-shadow(1px 1px 3px rgb(15, 15, 15));
  
  box-shadow:       0 0 0 0 rgba(0, 0, 0, 1);
	-webkit-transform:        scale(1);
	        transform:        scale(1);
	-webkit-animation:        pulse 2s infinite;
	        animation:        pulse 2s infinite;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}




html, body {
  font-family:        'Open Sans', sans-serif, Arial, Helvetica;
  font-weight:        300;
  margin:             0px !important; 
  height:             100%;
  font-size:          1rem;
}


/* .button-group-position{
  position: relative;
  top: 5%;
  right: 3%;
} */


  /* @media screen and (max-width: 500px) {

    .panelButton{
      width:  8vw;
      height: 8vw;
    }
  } */


  .brightness-value{
    /* position:       absolute;
    top:            10%;
    left:           50%;
    transform:      translate(-50%, -50%); */
    color:          rgb(63, 109, 179);
    font-size:      1.2rem;
    font-weight:    400;
    text-shadow:    1px 1px 8px rgb(177, 198, 211);
    padding-top:    0.3rem;
    width:          auto;
    /* display:        inline-block; */
  }

  .ambient-light-slider{
    position:        absolute;
    top:             80%;
    left:            50%;
    width:           auto;
    min-width:       70%;
    -webkit-transform:       translate(-50%, -50%);
            transform:       translate(-50%, -50%);
    padding:         0.3rem;
    border-radius:   1rem;

    /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
    white-space:      nowrap;
    background-color: rgba(229, 248, 244, 0.1);
  }


  .panel-drop-down-menu{
    background-color:   rgba(255,255,255, 0);
  }



  /* Media Query for Mobile Devices */
  @media (max-width: 480px) {
  
    /* body {
      background-color: rgb(255, 136, 0);
    } */

    /* .brightness-value-pos{
      top:            15%;
      left:           50%;
    }

    .brightness-value-font-size{
      font-size:      1rem;
    } */

    .panel{
      position:         absolute;
      top:              85%;
      left:             50%;
      -webkit-transform:        translate(-50%, -50%);
              transform:        translate(-50%, -50%);
      width:            auto;
      max-width:        100%;
      
      /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
      white-space:      nowrap;
      border-radius:    1rem;
      padding-left:     1.8rem;
      padding-right:    1.8rem;
      background-color: rgba(214, 214, 214, 0.4);
    }

    .panelButton{
      width:              auto;
      height:             auto;
      max-width:          8vw;
      max-height:         8vw;
      background-color:   rgba(255,255,255, 0.3);
      margin:             0.5rem;
      border-radius:      0.75rem;
      transition:         background-color 0.2s ease-in-out, -webkit-transform 0.2s;
      transition:         background-color 0.2s ease-in-out, transform 0.2s;
      transition:         background-color 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
      box-shadow:         1px 1px 8px grey;
    }
  
    .panelButton:hover{ /* IE 9 */
      -webkit-transform:  scale(1.2); /* Safari 3-8 */
      transform:          scale(1.2);
      background:       rgba(203, 232, 255, 0.7);
      box-shadow:         1px 1px 15px grey;
    }
  }
    
  /* Media Query for low resolution Tablets, Ipads */
  @media (min-width: 481px) and (max-width: 767px) {
      /* body {
          background-color: yellow;
      } */

      .panel{
        position:         absolute;
        top:              90%;
        left:             50%;
        -webkit-transform:        translate(-50%, -50%);
                transform:        translate(-50%, -50%);
        width:            auto;
        max-width:        90%;

        /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
        white-space: nowrap;
        border-radius:    1rem;
        padding-left:     1.8rem;
        padding-right:    1.8rem;
        background-color: rgba(214, 214, 214, 0.6);
      }

      .panelButton{
        width:              5vw;
        height:             5vw;
        min-width:          4vw;
        min-height:         4vw;
        background-color:   rgba(255,255,255, 0.3);
        margin:             0.5rem;
        border-radius:      0.75rem;
        transition:         background-color 0.2s ease-in-out, -webkit-transform 0.2s;
        transition:         background-color 0.2s ease-in-out, transform 0.2s;
        transition:         background-color 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
        box-shadow:         1px 1px 8px grey;
      }
    
      .panelButton:hover{ /* IE 9 */
        -webkit-transform:  scale(1.2); /* Safari 3-8 */
        transform:          scale(1.2);
        background:       rgba(203, 232, 255, 0.7);
        box-shadow:         1px 1px 15px grey;
      }
  }
    
  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px){
      /* body {
          background-color: blue;
      } */

      .panel{
        position:         absolute;
        top:              90%;
        left:             50%;
        -webkit-transform:        translate(-50%, -50%);
                transform:        translate(-50%, -50%);
        width:            auto;
        max-width:        90%;

        /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
        white-space: nowrap;
        border-radius:    1rem;
        padding-left:     1.8rem;
        padding-right:    1.8rem;
        background-color: rgba(214, 214, 214, 0.6);
      }

      .panelButton{
        width:              5vw;
        height:             5vw;
        min-width:          4vw;
        min-height:         4vw;
        background-color:   rgba(255,255,255, 0.3);
        margin:             0.5rem;
        border-radius:      0.75rem;
        transition:         background-color 0.2s ease-in-out, -webkit-transform 0.2s;
        transition:         background-color 0.2s ease-in-out, transform 0.2s;
        transition:         background-color 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
        box-shadow:         1px 1px 8px grey;
      }
    
      .panelButton:hover{ /* IE 9 */
        -webkit-transform:  scale(1.2); /* Safari 3-8 */
        transform:          scale(1.2);
        background:       rgba(203, 232, 255, 0.7);
        box-shadow:         1px 1px 15px grey;
      }
  }
    
  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px){
      /* body {
          background-color: green;
      } */

      .panel{
        position:         absolute;
        top:              90%;
        left:             50%;
        -webkit-transform:        translate(-50%, -50%);
                transform:        translate(-50%, -50%);
        width:            auto;
        max-width:        90%;
        
        /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
        white-space: nowrap;
        border-radius:    1rem;
        padding-left:     1.8rem;
        padding-right:    1.8rem;
        background-color: rgba(214, 214, 214, 0.6);
      }

      .panelButton{
        width:              4vw;
        height:             4vw;
        min-width:          3vw;
        min-height:         3vw;
        background-color:   rgba(255,255,255, 0.3);
        margin:             0.5rem;
        border-radius:      0.75rem;
        transition:         background-color 0.2s ease-in-out, -webkit-transform 0.2s;
        transition:         background-color 0.2s ease-in-out, transform 0.2s;
        transition:         background-color 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
        box-shadow:         1px 1px 8px grey;
      }
    
      .panelButton:hover{ /* IE 9 */
        -webkit-transform:  scale(1.08); /* Safari 3-8 */
        transform:          scale(1.08);
        background:       rgba(203, 232, 255, 0.7);
        box-shadow:         1px 1px 15px grey;
      }
  }
    
  /* Media Query for Large screens */
  @media (min-width: 1281px) and (max-width: 2560px) {
    /* body {
      background-color: rgb(128, 0, 0);
    } */

    .panel{
      position:         absolute;
      top:              90%;
      left:             50%;
      -webkit-transform:        translate(-50%, -50%);
              transform:        translate(-50%, -50%);
      width:            auto;
      max-width:        90%;

      /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
      white-space: nowrap;
      border-radius:    1rem;
      padding-left:     1.8rem;
      padding-right:    1.8rem;
      background-color: rgba(214, 214, 214, 0.6);
    }
    
    .panelButton{
      width:              auto;
      height:             auto;
      min-width:          2.7vw;
      min-height:         2.7w;
      max-width:          3vw;
      max-height:         3vw;
      background-color:   rgba(255,255,255, 0.3);
      margin:             0.5rem;
      border-radius:      0.75rem;
      transition:         background-color 0.2s ease-in-out, -webkit-transform 0.2s;
      transition:         background-color 0.2s ease-in-out, transform 0.2s;
      transition:         background-color 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
      box-shadow:         1px 1px 8px grey;
    }
  
    .panelButton:hover{ /* IE 9 */
      -webkit-transform:  scale(1.08); /* Safari 3-8 */
      transform:          scale(1.08);
      background:       rgba(203, 232, 255, 0.7);
      box-shadow:         1px 1px 15px grey;
    }
  }

  /* Media Query for Large screens */
  @media (min-width: 2561px) {
    /* body {
      background-color: rgb(0, 128, 32);
    } */

    .panel{
      position:         absolute;
      top:              90%;
      left:             50%;
      -webkit-transform:        translate(-50%, -50%);
              transform:        translate(-50%, -50%);  
      width:            auto;
      max-width:        90%;

      /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
      white-space: nowrap;
      border-radius:    1rem;
      padding-left:     1.8rem;
      padding-right:    1.8rem;
      background-color: rgba(214, 214, 214, 0.6);
    }
    
    .panelButton{
      width:              auto;
      height:             auto;
      /* min-width:          2.5vw;
      min-height:         2.5w; */
      max-width:          2.3vw;
      max-height:         2.3vw;
      background-color:   rgba(255,255,255, 0.3);
      margin:             0.5rem;
      border-radius:      0.75rem;
      transition:         background-color 0.2s ease-in-out, -webkit-transform 0.2s;
      transition:         background-color 0.2s ease-in-out, transform 0.2s;
      transition:         background-color 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
      box-shadow:         1px 1px 8px grey;
    }
  
    .panelButton:hover{ /* IE 9 */
      -webkit-transform:  scale(1.08); /* Safari 3-8 */
      transform:          scale(1.08);
      background:       rgba(203, 232, 255, 0.7);
      box-shadow:         1px 1px 15px grey;
    }
  }




.span {
  transition:     300ms all ease;
  opacity:        0;
  will-change:    opacity;
}

/* .show {
  opacity: 1;
} */

  
